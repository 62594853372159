import React from 'react';
import {Helmet} from 'react-helmet-async';

export default function HeadTags({title, children}) {

    return <Helmet>
        <title>{title || ''}{title ? ' | ' : ''}Fin.</title>
        {children}
    </Helmet>
}
