export const LOGGED_IN_LANDING_ROUTE = "/"
export const LOGIN_PAGE_ROUTE = "/login"
export const DEFAULT_LANDING_ROUTE = LOGIN_PAGE_ROUTE

export const PAGINATION_LIMIT_OPTIONS = [10, 20, 50, 100];

export const DATE_FORMATS = {
    DATE_READABLE: "MMM d, yyyy",
    DATE_API_REQUEST: "yyyy-MM-dd",
}

export const formatFloatToLocaleString = (floatValue) => floatValue ? floatValue.toLocaleString(
    undefined,
    {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }
) : 0;
