import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function FullPageOverlayLoader() {
    return <div
        className={`d-flex flex-column justify-content-center align-items-center position-fixed top-0 start-0 bottom-0 end-0`}
    >
        <Spinner animation="border" role="status" variant={"warning"}>
            <span className="visually-hidden">Loading…</span>
        </Spinner>
    </div>;
}
