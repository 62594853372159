import {createSlice} from '@reduxjs/toolkit'

export const commonSlice = createSlice({
    name: 'common',
    initialState: {
        apiToken: false,
    },
    reducers: {
        updateApiToken: (state, payload) => {
            state.apiToken = payload.payload;
        },
    },
});

export const {updateApiToken} = commonSlice.actions;

export default commonSlice.reducer;
