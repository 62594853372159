import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {ReactNotifications} from "react-notifications-component";
import {BrowserRouter} from "react-router-dom";
import FullPageOverlayLoader from "./components/generic/Loaders/FullPageOverlayLoader";
import store from "./redux/store";
import {HelmetProvider} from 'react-helmet-async';

// import styles
import "react-datepicker/dist/react-datepicker.css";
import 'react-notifications-component/dist/theme.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ReactNotifications/>
            <HelmetProvider>
                <BrowserRouter>
                    <Suspense fallback={<FullPageOverlayLoader/>}>
                        <App/>
                    </Suspense>
                </BrowserRouter>
            </HelmetProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
