import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {LOGGED_IN_LANDING_ROUTE} from "../../../utils/constants";
import {useSelector} from "react-redux";

export default function RequireLoggedOut({children}) {
    const apiToken = useSelector(store => store.common.apiToken);

    const navigate = useNavigate();

    useEffect(() => {
        if (apiToken) {
            navigate(LOGGED_IN_LANDING_ROUTE, {replace: true});
        }
    }, [apiToken])

    if (!!apiToken) {
        return null;
    } else {
        return children;
    }
}
