import {lazy, useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import RequireLoggedOut from "./components/generic/RoutesWrappers/RequireLoggedOut";
import RequireLoggedIn from "./components/generic/RoutesWrappers/RequireLoggedIn";
import {main_api} from "./utils/axios_helper";
import {useSelector} from "react-redux";
import HeadTags from "./components/generic/HeadTags/HeadTags";
import logo512 from "./assets/logo512.png";
import favicon from "./assets/favicon.png";

const LoginPage = lazy(() => import('./pages/login'));

const DashboardPage = lazy(() => import('./pages'));
const LedgerPage = lazy(() => import('./pages/ledger'));
const BalanceSheetPage = lazy(() => import('./pages/balance-sheet'));
const ProfitLossPage = lazy(() => import('./pages/profit-loss'));
const TransactionsPage = lazy(() => import('./pages/transactions'));

export default function App() {
    const apiToken = useSelector(store => store.common.apiToken);

    useEffect(() => {
        if (apiToken) {
            main_api.defaults.headers.common.Authorization = `Token ${apiToken}`;
        } else {
            delete main_api.defaults.headers.common.Authorization;
        }
    }, [apiToken])

    return <>
        <HeadTags>
            <link rel={"icon"} href={favicon}/>
            <link rel={"apple-touch-icon"} href={logo512}/>
        </HeadTags>
        <Routes>
            <Route
                path="login"
                element={<RequireLoggedOut><LoginPage/></RequireLoggedOut>}
            />

            <Route
                path="/"
                element={<RequireLoggedIn><DashboardPage/></RequireLoggedIn>}
            />

            <Route
                path="/ledger"
                element={<RequireLoggedIn><LedgerPage/></RequireLoggedIn>}
            />

            <Route
                path="/balance-sheet"
                element={<RequireLoggedIn><BalanceSheetPage/></RequireLoggedIn>}
            />

            <Route
                path="/profit-loss"
                element={<RequireLoggedIn><ProfitLossPage/></RequireLoggedIn>}
            />

            <Route
                path="/transactions"
                element={<RequireLoggedIn><TransactionsPage/></RequireLoggedIn>}
            />

            {/*<Route*/}
            {/*    path=""*/}
            {/*    element={<LandingPage/>}*/}
            {/*/>*/}
        </Routes>
    </>
}
